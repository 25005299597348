import React, { FC, useState } from 'react';
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { formatPrice } from '../../../selectors';
import { DebtModel } from '../../../models/DebtModel';

import AgencyDebtModal from './AgencyDebtModal';
import { IAgent } from '../../../models/AgentModel';
import moment from 'moment';
import { HeaderModel } from '../../../models/CommonModel';
import SortableColumn from '../../../components/Table/SortableColumn';
type Props = {
    data: Array<IAgent>
    handleSubmit: (values: any, actions: any) => void
    handleViewDetail: (item: IAgent) => void
    handleReceiptVoucher: (item: IAgent) => void,
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
};

const AgencyDebtTable: FC<Props> = ({ data, handleViewDetail, handleReceiptVoucher, header, hideCols, openColConfig, handleSort }) => {
    const intl = useIntl();
    const [id , setId] = useState<number>();
    const history = useHistory();
    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <i
                                className="fa fa-cogs"
                                style={{ cursor: 'pointer' }}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            {th.label}
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}>{th.label}</th>
                                )
                            }
                        })}
                        <th style={{ minWidth: "280px" }}>{intl.formatMessage({id: 'COMMON.ACTION_TEXT'})}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: IAgent, index: number) => {
                        var itemDebt = item.order_details_sum_sub_total - item.ability_sum_value;
                        return (
                            <tr key={`ability_${index}`} className="cursor-pointer">
                                <td></td>
                                {!hideCols.includes('name') && (
                                <td>{item.name}</td>
                                )}
                                {!hideCols.includes('sales_id') && (
                                <td>{item.sales?.name}</td>
                                )}
                                {!hideCols.includes('ability_max_date') && (
                                <td>{item.ability_max_date ? moment(item.ability_max_date).format('DD-MM-YYYY') : ''}</td>
                                )}
                                {!hideCols.includes('debt') && (
                                <td>{formatPrice(itemDebt)}</td>
                                )}
                                <td>
                                    <button onClick={() => handleViewDetail(item)} className='btn btn-primary'>Xem chi tiết</button>
                                    <button onClick={() => handleReceiptVoucher(item)} className='btn btn-primary ms-2'>Tạo phiếu thu</button>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default AgencyDebtTable;