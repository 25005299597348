import React, { FC, useMemo } from 'react';
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { AbilityModel } from '../../models/AbilityModel';
import { PAYMENT_VOUCHER, RECIEPT_VOUCHER } from '../../constants/common';
import { formatPrice } from '../../selectors';
import { IOrder, SalesReportItemModel } from '../../models/OrderModel';
import moment from 'moment';
import { HeaderModel } from '../../models/CommonModel';
import SortableColumn from '../../components/Table/SortableColumn';

type Props = {
    data: Array<SalesReportItemModel>,
    header: HeaderModel[],
    hideCols: string[],
    openColConfig: () => void,
    handleSort: (sortBy: string, direction: 'asc' | 'desc' | 'none') => void
};

const SalesReportTable: FC<Props> = ({ data, header, hideCols, openColConfig, handleSort }) => {
    const intl = useIntl();

    const history = useHistory();

    return (
        <div className='table-responsive'>
            <table className="table table-hover table-bordered table-striped border gy-7 gs-7">
                <thead>
                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                        <th>
                            <i
                                className="fa fa-cogs"
                                style={{ cursor: 'pointer' }}
                                onClick={openColConfig}
                            ></i>
                        </th>
                        {header.map((th, index) => {
                            if (!hideCols.includes(th.field)) {
                                if (th.sortable) {
                                    return (
                                        <SortableColumn
                                            key={'th-' + index}
                                            sortBy={th?.field}
                                            onSortChange={handleSort}
                                            style={th?.style ?? {}}
                                        >
                                            <strong>{th.label}</strong>
                                        </SortableColumn>
                                    )
                                }
                                return (
                                    <th style={th?.style ?? {}}><strong>{th.label}</strong></th>
                                )
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item: SalesReportItemModel, index: number) => {
                        return (
                            <tr key={`ability_${index}`} className="cursor-pointer">
                                <td></td>
                                {!hideCols.includes('order_code') && (
                                <td>{item.order_code}</td>
                                )}
                                {!hideCols.includes('agency_name') && (
                                <td>{item.agency_name}</td>
                                )}
                                {!hideCols.includes('total_expenses') && (
                                <td>{formatPrice(item.total_expenses)}</td>
                                )}
                                {!hideCols.includes('total_sales_no_vat') && (
                                <td>{formatPrice(item.total_sales_no_vat)}</td>
                                )}
                                {!hideCols.includes('total_vat') && (
                                <td>{formatPrice(item.total_vat)}</td>
                                )}
                                {!hideCols.includes('total_sales') && (
                                <td>{formatPrice(item.total_sales)}</td>
                                )}
                                {!hideCols.includes('profit') && (
                                <td>{formatPrice(item.total_sales - item.total_expenses - item.total_vat)}</td>
                                )}
                                {!hideCols.includes('approved_at') && (
                                <td>{moment(item.approved_at).format('DD-MM-YYYY')}</td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default SalesReportTable;