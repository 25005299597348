import AsyncSelect from "react-select/async";
import {FormikValues} from "formik";
import React, {FC, useEffect, useMemo, useState} from "react";
import * as AgentCRUD from "../agent/AgentCRUD";
import {ISearchAgent} from "../../models/OrderModel";
import {IAgent} from "../../models/AgentModel";
import {debounce, find} from "lodash";

interface IAgentOption {
  label: string,
  value: number
}

const initSearchAgent = {
  page: 1,
  per_page: 200,
  name: ''
}

type TProps = {
  values?: FormikValues,
  handleChange: (params: object) => void;
  agentsAppend: any,
  name: string,
  forwardRef?: any,
  defaultSelected?: string
  storeId?: number,
  salesId?: number,
  isDefaultAgent?: boolean
}

const AsyncSelectAgent: FC<TProps> = ({values, handleChange, agentsAppend, name, forwardRef, defaultSelected, storeId,salesId, isDefaultAgent}) => {
  const [agents, setAgents] = useState<IAgent[]>()

  useEffect(() => {
    getAgency(initSearchAgent, defaultSelected);
  }, []);

  useEffect(() => {
    getAgency(initSearchAgent, defaultSelected);
  }, [storeId]);

  useEffect(() => {
    getAgency(initSearchAgent, defaultSelected);
  }, [salesId]);

  useEffect(() => {
    if (agentsAppend?.label) {
      setAgents([...agents || [], agentsAppend]);
    }
  }, [agentsAppend?.label]);

  let agentOptions = useMemo(() => agents?.map(agent => ({label: agent.name, value: agent.id})), [agents])

  const getAgency = async (params: ISearchAgent, defaultSelected?: string) => {
    if (storeId) {
      params = {...params, store_id: storeId}
    }
    if (salesId) {
      params = {...params, sales_id: salesId}
    }
    const {data: resData} = await AgentCRUD.getAllAgents({...params})
    const listAgents = [...resData?.data || []]
    setAgents(listAgents);
    if ((!!defaultSelected && forwardRef.current) || isDefaultAgent) {
      if (!!defaultSelected) {
        var opt = find(listAgents, ['id', parseInt(defaultSelected)]);
      } else {
        var opt = find(listAgents, ['is_default', 1]);
      }
      if (opt) {
        forwardRef.current.setValue({value: opt.id, label: opt.name})
      }
    }
  }

  const fetchOptAgent = async (inputVal: string, callback: (result: IAgentOption[]) => void): Promise<void> => {
    let params: any = {name: inputVal};
    if (storeId) {
      params = {...params, store_id: storeId}
    }
    if (salesId) {
      params = {...params, sales_id: salesId}
    }
    const {data} = await AgentCRUD.getAllAgents({...params});

    callback(data.data.reduce(function (result: IAgentOption [], agent) {
      // if (agent?.name?.toLowerCase().includes(inputVal.toLowerCase())) {
      result.push({label: agent.name, value: agent.id});
      // }
      return result;
    }, []));
  }
  const _loadSuggestions = (inputVal: string, callback: (result: IAgentOption[]) => void) => {
    fetchOptAgent(inputVal, callback)
  }

  const loadSuggestions = debounce(_loadSuggestions, 1000)
  return <>
    <AsyncSelect className="mb-2" isClearable cacheOptions loadOptions={loadSuggestions}
      defaultOptions={agentOptions} value={values}
      onChange={(selectedOption: IAgentOption) => {
        handleChange({target: {name, value: selectedOption}});
      }}
      loadingMessage={() => "Đang tải..."}
      noOptionsMessage={() => "Không có dữ liệu"}
      id={name} name={name} placeholder=""
      ref={forwardRef}/>
  </>
}
export default AsyncSelectAgent;