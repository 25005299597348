// usePermissionChecker.ts
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../setup';
import { IAccount } from '../modules/auth/models/AccountModel';
import { IPermission } from '../modules/auth/models/PermissionsModel';
import { SENTO } from '../constants/common';
import { SALES_ROLE } from '../constants/role';
export const usePermissionChecker = () => {
  const user: IAccount = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IAccount;
  const role = user?.roles as any;
  const permissions: IPermission[] = (role && role.length > 0) ? role[0].permissions as IPermission[] : [];

  const hasSentoPermission = (userRole: any) => {
    return userRole && userRole.length > 0 && userRole[0]?.name === SENTO;
  };

  const hasPermission = (permissionName: string): boolean => {

    if (hasSentoPermission(role)) {
      return true;
    }

    if (!Array.isArray(permissions)) {
      return false;
    }

    for (const modelPermissions of permissions) {
      if (modelPermissions?.name === permissionName) {
        return true;
      }
    }

    return false;
  };

  return { hasPermission };
};

export const Sento = () => {
  return false;
  const user: IAccount = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IAccount;
  const role = user?.roles as any;
  return (role && role?.length > 0) && role[0]?.name === SENTO;
}

export const IsSentoAdmin = () => {
  const user: IAccount = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IAccount;
  const role = user?.roles as any;
  return (role && role?.length > 0) && role[0]?.name === SENTO && user.email === 'superadmin@gmail.com';
}

export const UserLoggedIn = () => {
  const user: IAccount = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IAccount;
  return user;
}

export const IsSales = () => {
  const user: IAccount = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as IAccount;
  const role = user?.roles as any;
  return (role && role?.length > 0) && role[0]?.id === SALES_ROLE;
}